import { useEffect,useState } from "react";
import { getResumes } from "../../requests";
import CandidateCard from "../../components/candidatecard";
import { List } from "antd";
import FullScreenLoader from "../../components/Loader";

const Career = () => {

  const [loading,setLoading]=useState(false)


  const[CandidatesDetails,setCandidatesDetails]=useState([])
  const ResumesData = async () => {
    try {
      setLoading(true)
      const resp = await getResumes();
      setCandidatesDetails(resp.data)
      
    } catch (error) {
      console.log(error);
    }finally{
      setLoading(false)
    }
  };

  useEffect(() => {
    ResumesData();
  }, []);


// console.log(CandidatesDetails)

return (
  <div>
    <FullScreenLoader loading={loading}/>
    <h1>Career</h1>
    <List
      grid={{ gutter: 16, column: 4 }}
      dataSource={CandidatesDetails}
      renderItem={(candidate) => (
        <List.Item>
          <CandidateCard candidate={candidate} />
        </List.Item>
      )}
    />
  </div>
);
};

export default Career;
