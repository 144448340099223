import { useState } from "react";
import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { dashboardData, dashboardDataorganizer } from "../../requests";
import { Spin } from "antd";

const Home = () => {
  const [data, setData] = useState("");
  const [isOrganizer, setIsOrganizer] = useState(false);
   const [loading, setLoading] = useState(true);


  useEffect(() => {
    const organizerUID = sessionStorage.getItem("organizerUID");

    if (organizerUID !== null) {
      setIsOrganizer(true);
    }
  }, []);
  const Data = async () => {
    try {
      setLoading(true)
      const organizerUID = sessionStorage.getItem("organizerUID");
      if (organizerUID) {
        const resp = await dashboardDataorganizer(
          localStorage.getItem("organizerName")
        );
        setData(resp.data);
      } else {
        const resp = await dashboardData();
        setData(resp.data);
      }
    } catch (err) {
      console.log(err);
    }finally{
      setLoading(false)
    }
  };

  useEffect(() => {
    Data();
  }, [isOrganizer]);

  useEffect(() => {
    if (!isOrganizer) {
      Data();
    }
  }, []);

  return (
    <div className={styles.dashboard}>
      {/* <h1>Dashboard</h1> */}
      <div className={styles.cardContainer}>
        <div className={`${styles.card} ${styles.card1}`}>
          <h2>Bookings</h2>
          <p>{loading ? <Spin /> : data?.totalBookings}</p>
        </div>
        <div className={`${styles.card} ${styles.card1}`}>
          {isOrganizer ? (
            <>
              <h2>Total Seats</h2>
              <p>{loading ? <Spin /> : data?.totalSeats}</p>
            </>
          ) : (
            <>
              <h2>Events</h2>
              <p>{loading ? <Spin /> : data?.totalTickets}</p>
            </>
          )}
        </div>

        {isOrganizer && (
          <>
            <div className={`${styles.card} ${styles.card1}`}>
              <h2>Total Sales</h2>
              <p>{loading ? <Spin /> : data?.totalSales}</p>
            </div>
          </>
        )}

        {!isOrganizer && (
          <>
            <div className={`${styles.card} ${styles.card1}`}>
              <h2>Users</h2>
              <p>{loading ? <Spin /> : data?.totalUsers}</p>
            </div>
            <div className={`${styles.card} ${styles.card1}`}>
              <h2>Cities</h2>
              <p>{loading ? <Spin /> : data?.totalCities}</p>
            </div>
          </>
        )}
      </div>

      {!isOrganizer && (
        <>
          <div className={styles.cardContainer}>
            <div className={`${styles.card} ${styles.card1}`}>
              <h2>Total Sales</h2>
              <p>{loading ? <Spin /> : `₹ ${data?.totalSales}`}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
