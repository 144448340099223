// import React, { useEffect, useState } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { Button } from "primereact/button";
// import { Dialog } from "primereact/dialog";
// import "primereact/resources/themes/lara-light-cyan/theme.css";
// import "primeicons/primeicons.css";
// import {notification} from 'antd'
// import AWSS3Uploader from "../../components/s3uploader";
// import { partnersAdd,partners,partnerDelete } from "../../requests";
// const Partners = () => {
//   const [Partners, setPartners] = useState([]);
//   const [selectedPartner, setSelectedPartner] = useState(null);
//   const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
//   const [displayAddPartnerDialog, setDisplayAddPartnerDialog] = useState(false);
//   const [newPartner, setNewPartner] = useState({
//     partnerImage: "",
//     partnerName: "",
//       partnerPosition: ''
//   });

//   const fetchPartners = async() => {
//     try{
//       const resp=await partners()
//       // console.log(resp)
    
//         setPartners(resp.data);

//     }catch(err){
//       console.log(err)
//     }
 
//   };

//   useEffect(() => {
//     fetchPartners();
//   }, []);

//   const addPartner = () => {
//     setDisplayAddPartnerDialog(true);
//   };


//   const saveNewPartner = async() => {
//   try{
//     const token=localStorage.getItem('token')
//     const resp=await partnersAdd(newPartner,token)
//     notification.success({
//       message: 'Success',
//       description: 'Partner added successfully!',
//     });


//     setNewPartner({
//       partnerImage: '',
//       partnerName: '',
//       partnerPosition: "" 
//     });
   
//     setDisplayAddPartnerDialog(false);
//   }catch(err){
//     notification.error({
//       message: err.response ? err.response.data.message : 'Error',
//             description: 'Error adding partner. Please try again.',
//     });
//     console.log(err)

//   }finally{
//     fetchPartners()
//   }}

  

//   const cancelAddPartner = () => {
//     setNewPartner({ partnerImage: "", partnerName: "" ,  partnerPosition: ''});
//     setDisplayAddPartnerDialog(false);
//   };
//   const columns = [
//     { field: "partnerImage", header: "Partner Image" },
//     { field: "partnerName", header: "Partner Name" },
//     { field: "partnerPosition", header: "Position" },
//     { field: "actions", header: "Actions" },
//   ];

//   const confirmDelete = (partner) => {
//     setSelectedPartner(partner);
//     setDisplayDeleteDialog(true);
//   };

//   const deleteConfirmed = async () => {
//     try {
//       const token=localStorage.getItem('token')
//       console.log('Deleting partner with id:', selectedPartner._id);
//       const resp=await partnerDelete(selectedPartner._id,token)
      
//       setPartners((prevPartners) =>
//         prevPartners.filter((partner) => partner.id !== selectedPartner.id)
//       );
//       setDisplayDeleteDialog(false);
//     } catch (err) {
//       console.log(err);
//     }finally{
//       fetchPartners()
//     }
//   };

//   const deleteCancel = () => {
//     setDisplayDeleteDialog(false);
//   };

//   return (
//     <div>
//       <div style={{ marginBottom: "10px" }}>
//         {/* Add Partner Button - Implement the logic for adding partners */}
//         <Button
//           type="button"
//           label="Add Partner"
//           icon="pi pi-plus"
//           rounded
//           onClick={addPartner}
//         />
//       </div>
//       <DataTable
//         value={Partners}
//         paginator
//         rows={10}
//         dataKey="id"
//         header="Partners"
//         emptyMessage="No Partners found."
//       >
//         {columns.map((column, index) => (
//           <Column
//             key={index}
//             field={column.field}
//             header={column.header}
//             body={
//               column.field === "actions"
//                 ? (rowData) => (
//                     <div>
//                       <Button
//                         icon="pi pi-trash"
//                         className="p-button-danger"
//                         onClick={() => confirmDelete(rowData)}
//                       />
//                     </div>
//                   )
//                 : column.field === "partnerImage"
//                 ? (rowData) => <img src={rowData.partnerImage} alt="Partner" height={60}/>
//                 : column.field === "partnerName"
//                 ? (rowData) => <span>{rowData.partnerName}</span>
//                 : column.body
//             }
//           />
//         ))}
//       </DataTable>

//       {/* Delete Confirmation Dialog */}
//       <Dialog
//         header="Confirmation"
//         visible={displayDeleteDialog}
//         style={{ width: "50vw" }}
//         modal
//         onHide={deleteCancel}
//         footer={
//           <div>
//             <Button label="Cancel" icon="pi pi-times" onClick={deleteCancel} />
//             <Button
//               label="Delete"
//               icon="pi pi-check"
//               onClick={deleteConfirmed}
//               className="p-button-danger"
//             />
//           </div>
//         }
//       >
//         <div>
//           <p>Are you sure you want to delete this partner?</p>
//         </div>
//       </Dialog>


//       <Dialog
//         header="Add Partner"
//         visible={displayAddPartnerDialog}
//         style={{ width: "50vw" }}
//         modal
//         onHide={cancelAddPartner}
//         footer={
//           <div>
//             <Button
//               label="Save"
//               icon="pi pi-check"
//               onClick={saveNewPartner}
//             />
//             <Button
//               label="Cancel"
//               icon="pi pi-times"
//               onClick={cancelAddPartner}
//             />
//           </div>
//         }
//       >
        
//         <AWSS3Uploader
//          onUploadComplete={(url) => setNewPartner({ ...newPartner, partnerImage: url })}
         
//         />
//         <div style={{ marginTop: "10px",display:'flex',alignItems:'center',width:'60%' }} className="p-inputgroup">
       
//           <label htmlFor="partnerName">Partner Name:</label>
//           <input
//             type="text"
//             id="partnerName"
//             className="p-inputtext"
//             value={newPartner.partnerName}
//             onChange={(e) =>
//               setNewPartner({ ...newPartner, partnerName: e.target.value })
//             }
//           />
//         </div>
//         <div style={{ marginTop: "10px", display: 'flex', alignItems: 'center', width: '60%' }} className="p-inputgroup">
//           <label htmlFor="partnerPosition">Position:</label>
//           <input
//             type="number"
//             id="partnerPosition"
//             className="p-inputtext"
//             value={newPartner.partnerPosition}
//             onChange={(e) =>
//               setNewPartner({ ...newPartner, partnerPosition: e.target.value })
//             }
//           />
//         </div>
//       </Dialog>
//     </div>
//   );
// };

// export default Partners;


import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import { notification } from 'antd';
import AWSS3Uploader from "../../components/s3uploader";
import { partnersAdd, partners, partnerDelete, partnerUpdate } from "../../requests";

const Partners = () => {
  const [Partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [displayDeleteDialog, setDisplayDeleteDialog] = useState(false);
  const [displayAddPartnerDialog, setDisplayAddPartnerDialog] = useState(false);
  const [displayEditPartnerDialog, setDisplayEditPartnerDialog] = useState(false);
  const [newPartner, setNewPartner] = useState({
    partnerImage: "",
    partnerName: "",
    partnerPosition: ""
  });

  const [editPartnerid,setEditPartner]=useState("")

  const fetchPartners = async () => {
    try {
      const resp = await partners();
      setPartners(resp.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  const addPartner = () => {
    setNewPartner({
      partnerImage: "",
      partnerName: "",
      partnerPosition: ""
    });
    setDisplayAddPartnerDialog(true);
  };

  const saveNewPartner = async () => {
    try {
      const token = localStorage.getItem('token');
      await partnersAdd(newPartner, token);
      notification.success({
        message: 'Success',
        description: 'Partner added successfully!',
      });
      setDisplayAddPartnerDialog(false);
      fetchPartners();
    } catch (err) {
      notification.error({
        message: err.response ? err.response.data.message : 'Error',
        description: 'Error adding partner. Please try again.',
      });
    }
  };

  const cancelAddPartner = () => {
    setDisplayAddPartnerDialog(false);
  };

  const editPartner = (partner) => {
    setNewPartner(partner);  
    setEditPartner(partner._id)
    setDisplayEditPartnerDialog(true);
  };

  const saveEditedPartner = async () => {
    try {
      const token = localStorage.getItem('token');
      await partnerUpdate(newPartner, token,editPartnerid);  // API call to update partner
      notification.success({
        message: 'Success',
        description: 'Partner updated successfully!',
      });
      setDisplayEditPartnerDialog(false);
      fetchPartners();
    } catch (err) {
      notification.error({
        message: err.response ? err.response.data.message : 'Error',
        description: 'Error updating partner. Please try again.',
      });
    }
  };

  const cancelEditPartner = () => {
    setDisplayEditPartnerDialog(false);
  };

  const confirmDelete = (partner) => {
    setSelectedPartner(partner);
    setDisplayDeleteDialog(true);
  };

  const deleteConfirmed = async () => {
    try {
      const token = localStorage.getItem('token');
      await partnerDelete(selectedPartner._id, token);
      setPartners((prevPartners) =>
        prevPartners.filter((partner) => partner._id !== selectedPartner._id)
      );
      setDisplayDeleteDialog(false);
    } catch (err) {
      console.log(err);
    } finally {
      fetchPartners();
    }
  };

  const deleteCancel = () => {
    setDisplayDeleteDialog(false);
  };

  const columns = [
    { field: "partnerImage", header: "Partner Image" },
    { field: "partnerName", header: "Partner Name" },
    { field: "partnerPosition", header: "Position" },
    { field: "actions", header: "Actions" },
  ];

  return (
    <div>
      <div style={{ marginBottom: "10px" }}>
        <Button
          type="button"
          label="Add Partner"
          icon="pi pi-plus"
          rounded
          onClick={addPartner}
        />
      </div>
      <DataTable
        value={Partners}
        paginator
        rows={10}
        dataKey="_id"
        header="Partners"
        emptyMessage="No Partners found."
      >
        {columns.map((column, index) => (
          <Column
            key={index}
            field={column.field}
            header={column.header}
            body={
              column.field === "actions"
                ? (rowData) => (
                    <div>
                      <Button
                        icon="pi pi-pencil"
                        className="p-button-warning"
                        onClick={() => editPartner(rowData)}
                      />
                      <Button
                        icon="pi pi-trash"
                        className="p-button-danger"
                        onClick={() => confirmDelete(rowData)}
                        style={{ marginLeft: "10px" }}
                      />
                    </div>
                  )
                : column.field === "partnerImage"
                ? (rowData) => <img src={rowData.partnerImage} alt="Partner" height={60} />
                : column.body
            }
          />
        ))}
      </DataTable>

      {/* Delete Confirmation Dialog */}
      <Dialog
        header="Confirmation"
        visible={displayDeleteDialog}
        style={{ width: "50vw" }}
        modal
        onHide={deleteCancel}
        footer={
          <div>
            <Button label="Cancel" icon="pi pi-times" onClick={deleteCancel} />
            <Button
              label="Delete"
              icon="pi pi-check"
              onClick={deleteConfirmed}
              className="p-button-danger"
            />
          </div>
        }
      >
        <p>Are you sure you want to delete this partner?</p>
      </Dialog>

      {/* Add Partner Dialog */}
      <Dialog
        header="Add Partner"
        visible={displayAddPartnerDialog}
        style={{ width: "50vw" }}
        modal
        onHide={cancelAddPartner}
        footer={
          <div>
            <Button label="Save" icon="pi pi-check" onClick={saveNewPartner} />
            <Button label="Cancel" icon="pi pi-times" onClick={cancelAddPartner} />
          </div>
        }
      >
        <AWSS3Uploader
          onUploadComplete={(url) =>
            setNewPartner({ ...newPartner, partnerImage: url })
          }
        />
        <div style={{ marginTop: "10px", display: 'flex', alignItems: 'center', width: '60%' }} className="p-inputgroup">
          <label htmlFor="partnerName">Partner Name:</label>
          <input
            type="text"
            id="partnerName"
            className="p-inputtext"
            value={newPartner.partnerName}
            onChange={(e) =>
              setNewPartner({ ...newPartner, partnerName: e.target.value })
            }
          />
        </div>
        <div style={{ marginTop: "10px", display: 'flex', alignItems: 'center', width: '60%' }} className="p-inputgroup">
          <label htmlFor="partnerPosition">Position:</label>
          <input
            type="number"
            id="partnerPosition"
            className="p-inputtext"
            value={newPartner.partnerPosition}
            onChange={(e) =>
              setNewPartner({ ...newPartner, partnerPosition: e.target.value })
            }
          />
        </div>
      </Dialog>

      {/* Edit Partner Dialog */}
      <Dialog
        header="Edit Partner"
        visible={displayEditPartnerDialog}
        style={{ width: "50vw" }}
        modal
        onHide={cancelEditPartner}
        footer={
          <div>
            <Button label="Save" icon="pi pi-check" onClick={saveEditedPartner} />
            <Button label="Cancel" icon="pi pi-times" onClick={cancelEditPartner} />
          </div>
        }
      >
        <AWSS3Uploader
          onUploadComplete={(url) =>
            setNewPartner({ ...newPartner, partnerImage: url })
          }
        />
        <div style={{ marginTop: "10px", display: 'flex', alignItems: 'center', width: '60%' }} className="p-inputgroup">
          <label htmlFor="partnerName">Partner Name:</label>
          <input
            type="text"
            id="partnerName"
            className="p-inputtext"
            value={newPartner.partnerName}
            onChange={(e) =>
              setNewPartner({ ...newPartner, partnerName: e.target.value })
            }
          />
        </div>
        <div style={{ marginTop: "10px", display: 'flex', alignItems: 'center', width: '60%' }} className="p-inputgroup">
          <label htmlFor="partnerPosition">Position:</label>
          <input
            type="number"
            id="partnerPosition"
            className="p-inputtext"
            value={newPartner.partnerPosition}
            onChange={(e) =>
              setNewPartner({ ...newPartner, partnerPosition: e.target.value })
            }
          />
        </div>
      </Dialog>
    </div>
  );
};

export default Partners;
