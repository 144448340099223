import axios from "axios";

const BASE_URL = "https://demo.wildcardtickets.com";
// const BASE_URL = "http://localhost:5000";

const login = async (values) => {
  const response = await axios.post(
    `${BASE_URL}/superadmin/login`,
    values
  );
  
  return response;
};

const getTickets =async()=>{
  const response=await axios.get(`${BASE_URL}/ticket/getalltickets`)
  return response
}

const users=async(token)=>{
  const response=await axios.get(`${BASE_URL}/manageusers/allusers`,{headers:{Authorization:token}})
  return response
}

  const bookedtickets=async(token)=>{
    const response=await axios.get(`${BASE_URL}/bookticket/all`,{headers:{Authorization:token}})
    return response
  
}


const createTicket=async(token,values)=>{
  const response=await axios.post(`${BASE_URL}/ticket/createticket`,values,{headers:{Authorization:token}})
  return response
}

const deleteTicket=async(id,token)=>{
  const response=await axios.delete(`${BASE_URL}/ticket/deleteticket/${id}`,{headers:{Authorization:token}})
  return response
}

const editTicket=async(id,values,token)=>{
  const response=await axios.put(`${BASE_URL}/ticket/updateticket/${id}`,values,{headers:{Authorization:token}})
  return response
}

const getcities=async()=>{
  const response=await axios.get(`${BASE_URL}/city/get`)
  return response
}

const addCity = async (value, token) => {
  const response = await axios.post(`${BASE_URL}/city/addcity`, value, {
    headers: { Authorization: token },
  });
  return response;
};

const citydelete = async (id, token) => {
  const response = await axios.delete(`${BASE_URL}/city/delete/${id}`, {
    headers: { Authorization: token },
  });
  return response;
};

const getcategory=async()=>{
  const response=await axios.get(`${BASE_URL}/category/categories`)
  return response
}

const addcategory = async (value, token) => {
  const response = await axios.post(`${BASE_URL}/category/addcategory`, value, {
    headers: { Authorization: token },
  });
  return response;
};

const categorydelete = async (id, token) => {
  const response = await axios.delete(`${BASE_URL}/category/catdelete/${id}`, {
    headers: { Authorization: token },
  });
  return response;
};

const ticketFilter=async(value,token)=>{
  const response=await axios.post(`${BASE_URL}/ticket/filterbyorganizer`, value,{headers:{Authorization:token}})
  return response
}

const queries=async()=>{
  const response=await axios.get(`${BASE_URL}/contactus/getallqueries`)
  return response
}

const listevents=async()=>{
  const response=await axios.get(`${BASE_URL}/event/alllist`)
  return response
}

const addOrganizer = async (value, token) => {
  const response = await axios.post(`${BASE_URL}/superadmin/create`, value, {
    headers: { Authorization: token },
  });
  return response;
};

const getadmins=async()=>{
  const response=await axios.get(`${BASE_URL}/superadmin/getadmins`)
  return response
}

const getorganizers=async()=>{
  const response=await axios.get(`${BASE_URL}/superadmin/getorganizers`)
  return response
}


const bookingsOrganizer=async(value,token)=>{
  const response=await axios.post(`${BASE_URL}/bookticket/byOrganizer`,value,{headers:{Authorization:token}})
  return response
}

const getResumes=async()=>{
  const response=await axios.get(`${BASE_URL}/candidates/get`)
  return response
}

const partners=async()=>{
  const response=await axios.get(`${BASE_URL}/partners`)
  return response
}

export const partnerUpdate=async(value,token,id)=>{ 
  const response=await axios.put(`${BASE_URL}/partners/update/${id}`,value,{headers:{Authorization:token}})
  return response
}

const partnersAdd=async(value,token)=>{
  const response=await axios.post(`${BASE_URL}/partners/add`,value,{headers:{Authorization:token}})
  return response
}

const partnerDelete=async(id,token)=>{
  const response=await axios.delete(`${BASE_URL}/partners/delete/${id}`,{headers:{Authorization:token}})
  return response
}

const dashboardData=async()=>{
  const response=await axios.get(`${BASE_URL}/dashboard/stats`,)
  return response
}

const dashboardDataorganizer=async(organizerName)=>{
  const response=await axios.post(`${BASE_URL}/dashboard/byOrganizer/${organizerName}`,)
  return response
}

export  {dashboardDataorganizer,dashboardData,partnersAdd,partnerDelete,partners,getResumes,bookingsOrganizer,ticketFilter,getorganizers,getadmins,addOrganizer,listevents,queries,citydelete,categorydelete,getcategory,addcategory,addCity,login,getTickets,users,bookedtickets,createTicket,deleteTicket,editTicket,getcities} ;
