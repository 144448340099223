import React from 'react';
import { Spin } from 'antd';
import styles from './FullScreenLoader.module.css';

const FullScreenLoader = ({ loading }) => {
  if (!loading) return null;

  return (
    <div className={styles.loaderContainer}>
      <Spin size="large" />
    </div>
  );
};

export default FullScreenLoader;
