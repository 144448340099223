import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { format } from 'date-fns';
import FullScreenLoader from '../../components/Loader';

// import { bookedtickets } from '../../requests';

import "primereact/resources/themes/lara-light-cyan/theme.css";

import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Button } from 'primereact/button';
// import { Modal } from 'primereact/modal'
import { Modal } from 'antd';
import { bookedtickets,bookingsOrganizer } from '../../requests'; // Import your request function

export default function Bookings() {
  const [customers, setCustomers] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [currentQR, setCurrentQR] = useState(""); 
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: 'contains' },
    bookingId: { value: null, matchMode: 'startsWith' },
    // Add more filters as needed
  });

  const [loading,setLoading]=useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const token = localStorage.getItem('token');
        let resp;
    
        const organizerName = localStorage.getItem('organizerName');
        console.log('organizerName:', organizerName);
    
        if (organizerName !== null && organizerName !== undefined) {
          resp = await bookingsOrganizer( {organizerName:organizerName},token);
        } else {
          resp = await bookedtickets(token);
        }
    
        console.log(resp.data.bookedTicketsByOrganizer
          );
        setCustomers(getCustomers(resp.data.allBookedTickets));
        // setCustomers(getCustomers(fakeData));
      } catch (err) {
        console.error(err);
      }finally{
        setLoading(false)
      }
    };
  
    // const byOrganiser = async () => {
    //   try {
    //     const token = localStorage.getItem('token');
    //     const organizerName = localStorage.getItem('organizerName');
    //     console.log('organizerName:', organizerName);
  
    //     if (organizerName !== null && organizerName !== undefined) {
    //       const resp = await bookedtickets(token, organizerName);
    //       console.log(resp.data.allBookedTickets);
    //       setCustomers(getCustomers(resp.data.allBookedTickets));
    //       // setCustomers(getCustomers(fakeData));
    //     } else {
    //       // Handle the case when organizerName is null or undefined
    //       console.log('organizerName is null or undefined. Running fetchData...');
    //       fetchData();
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };
  
    // Check if organizerName exists and is not null or undefined
    // const organizerName = localStorage.getItem('organizerName');
    // console.log('Initial organizerName:', organizerName);
  
    // if (organizerName !== null && organizerName !== undefined) {
    //   byOrganiser();
    // } else {
    //   // Handle the case when organizerName is null or undefined
    //   console.log('organizerName is null or undefined. Running fetchData...');
      fetchData();
    
  }, []);
  


  // const getCustomers = (data) => {
  //   return [...(data || [])].map((d, index) => {
  //     d.serialNumber = index + 1;
  //     d.date = new Date(d.date);
  //     return d;
  //   });
  // };

  const getCustomers = (data) => {
    return [...(data || [])].map((user, index) => {
      const utcTimestamp = user.createdAt;
      const dateInUTC = new Date(utcTimestamp);
      const options = { timeZone: 'Asia/Kolkata' };
      const dateInIST = dateInUTC.toLocaleString('en-US', options);
  
      return {
        ...user,
        serialNumber: index + 1,
        createdAt: dateInIST,
      };
    });
  };

 
  const handleShowQR = (qrCode) => {
    setCurrentQR(qrCode);
    setShowModal(true);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={filters.global.value}
            onChange={(e) =>
              setFilters({ ...filters, global: { value: e.target.value, matchMode: 'contains' } })
            }
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt="flag"
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flag flag-${rowData.country.code}`}
          style={{ width: '24px' }}
        />
        <span>{rowData.country.name}</span>
      </div>
    );
  };

  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.representative;

    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={representative.name}
          src={`https://primefaces.org/cdn/primereact/images/avatar/${representative.image}`}
          width="32"
        />
        <span>{representative.name}</span>
      </div>
    );
  };

  const statuses = ['unqualified', 'qualified', 'new', 'negotiation', 'renewal'];

  const statusBodyTemplate = (rowData) => {
    return <Tag value={rowData.status} />;
  };

  const verifiedBodyTemplate = (rowData) => {
    return <TriStateCheckbox value={rowData.verified} />;
  };

  const header = renderHeader();

  // const renderHeader = () => {
  //   return (
  //     <div className="flex justify-content-end">
  //       <span className="p-input-icon-left">
  //         <i className="pi pi-search" />
  //         <InputText
  //           value={filters.global.value}
  //           onChange={(e) =>
  //             setFilters({ ...filters, global: { value: e.target.value, matchMode: 'contains' } })
  //           }
  //           placeholder="Keyword Search"
  //         />
  //       </span>
  //     </div>
  //   );
  // };

  // const header = renderHeader();

  const exportPdf = () => {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then(() => {
        const doc = new jsPDF.default(0, 0);
  
        const exportColumns = [
          { title: 'Sr. No.', dataKey: 'serialNumber' },
          { title: 'Booking ID', dataKey: 'bookingId' },
          { title: 'Ticket ID', dataKey: 'ticketId' },
          { title: 'Event Name', dataKey: 'eventName' },
          {
            title: 'Booked At',
            dataKey: 'createdAt',
            body: (rowData) => new Date(rowData.createdAt).toLocaleString(),
          },
          { title: 'Contact Info', dataKey: 'contactInfo' },
          { title: 'Name', dataKey: 'userName' },
          { title: 'Total Amount Paid', dataKey: 'totalAmountPaid' },
          {
            title: 'Seats',
            dataKey: 'seats',
            body: (rowData) => rowData.seats.map((seat) => `${seat.label}: ${seat.value}`).join('\n'),
          },
        ];
  
        const dataForPdf = customers.map((customer) => {
          // Convert seats to a string for proper display
          const seatsString = customer.seats.map((seat) => `${seat.label}: ${seat.value}`).join('\n');
          return {
            ...customer,
            seats: seatsString,
          };
        });
  
        doc.autoTable(exportColumns, dataForPdf);
        doc.save('bookings.pdf');
      });
    });
  };
  
  const formatDate = (dateTimeString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    };
  
    const formattedDate = new Date(dateTimeString).toLocaleString(undefined, options);
  
    return formattedDate === 'Invalid Date' ? dateTimeString : formattedDate;
  };

  const exportExcel = () => {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(customers);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      saveAsExcelFile(excelBuffer, 'bookings');
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let EXCEL_EXTENSION = '.xlsx';
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    });
  };

  

  return (
    <div>
      <FullScreenLoader loading={loading}/>
      <h1>Booked Tickets</h1>
      
      <div style={{display:'flex',gap:'10px',marginBottom:'10px'}}>
      <Button type="button" label='Export PDF' icon="pi pi-file-pdf"  rounded onClick={exportPdf} data-pr-tooltip="PDF" />
      <Button label="Export Excel" icon="pi pi-file-excel" rounded  onClick={exportExcel} />
      </div>
      <DataTable
        value={customers}
        paginator
        rows={10}
        dataKey="id"
        filters={filters}
        filterDisplay="row"
        header={header}
        emptyMessage="No Bookings found."
      >
        <Column field="serialNumber" header="Sr. No." />
        <Column field="bookingId" header="Booking ID"  filterPlaceholder="Search by Booking ID" />
        <Column field="ticketId" header="Ticket ID"  filterPlaceholder="Search by Ticket ID" />
        <Column field="eventName" header="Event Name"  filterPlaceholder="Search by Event Name" />
        {/* <Column field="venue" header="Venue"  filterPlaceholder="Search by Venue" /> */}
        {/* <Column field="city" header="City"  filterPlaceholder="Search by City" /> */}
        {/* <Column
          field="date"
          header="Date"
          body={(rowData) => rowData.date.toDateString()}
          
          filterPlaceholder="Search by Date"
        /> */}
        {/* <Column field="time" header="Time"  filterPlaceholder="Search by Time" /> */}
        <Column field="contactInfo" header="Contact Info"  filterPlaceholder="Search by User ID" />
        <Column field="contactMobile" header="Mobile"  filterPlaceholder="Search by Mobile" />
        <Column field="userName" header="Name"  filterPlaceholder="Search by Name" />
        <Column
        field="totalAmountPaid"
        header="Total Amount Paid"
        filterPlaceholder="Search by Total Amount Paid"
    />
    <Column
    field="createdAt"
    header="Booked At"
    body={(rowData) => new Date(rowData.createdAt).toLocaleString()}
    filterPlaceholder="Search by Date"
  />
  
     <Column
    field="seats"
    header="Seats"
    body={(rowData) => (
        <ul>
            {rowData.seats.map((seat, index) => (
                <li key={index}>
                    {`${seat.label}: ${seat.value}`}
                </li>
            ))}
        </ul>
    )}
    filterPlaceholder="Search by Seats"
/>
        <Column
          field="qrCode"
          header="QR Code"
          body={(rowData) => <Button label="Show QR" onClick={() => handleShowQR(rowData.qrCode)} />}
        />
      </DataTable>
      <Modal
        title="QR Code"
        visible={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
      >
        <img src={currentQR} alt="QR Code" />
      </Modal>
    </div>
  );
}
